import React from 'react';
import './App.scss';
import {
    AppBar,
    Box,
    Button, Card, CardContent,
    CardMedia,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Paper, ThemeProvider,
    Toolbar,
    Typography,
} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Image} from "@mui/icons-material";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);
const pages = ['No', 'Pages', 'yet'];
function App() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <Box>
            <AppBar id="davik-appbar" position="static" sx={{backgroundColor: "var(--main-color)"}}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="h6"
                        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                    >
                        DAVIK:DK
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>

                        <Button sx={{my: 2, color: 'white', display: 'none'}}>
                            Home
                        </Button>

                    </Box>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl" sx={{mt: "1rem"}}>
<Box component="section">
    <ThemeProvider theme={theme}>
    <Typography component="h1" variant="h1" sx={{
        color: "var(--secondary-color)",
        textShadow: "4px 4px 4px var(--main-color)",
        fontWeight: "bold"
    }}>NICKLAS:VIND</Typography>
    </ThemeProvider>
</Box>
            </Container>
            <Container maxWidth="xl" sx={{mt: "1rem"}}>

<Grid container spacing={2} alignItems="stretch" display="flex">
    <Grid item lg={6} xs={12} order={{xs:2, md:1}}>

            <Paper elevation={3} sx={{p: "1rem", backgroundColor: "var(--fourth-color)", boxSizing:"border-box"}}>

                <Card sx={{ maxWidth: "100%", backgroundColor:"var(--third-color)" }}>
                    <CardMedia
                        component="img"
                        alt="Teaching"
                        height="400"
                        image="img_1.webp"
                    />
                    <CardContent>
                        <Typography variant="body1">
                            Som person er jeg meget positiv, entusiastisk og proaktiv. Jeg ser udfordringer frem for problemer.
                            Jeg gør en stor ære i at være opdateret og være fagligt stærk, så jeg kan give mine kunder den bedste løsning. Socialt er jeg udadvendt, har nemt ved at skabe nye relationer, jeg er meget bevidst omkring mine omgivelser og er med til at skabe en god stemning. Kort sagt en stærk teamplayer.
                        </Typography>
                        <h4>Softwareudvikler GF forsikring</h4>
                        <p>
                            Jeg har ansvaret for hele gf forsikrings frontend, hvilket drejer sig om gfforsikring.dk og mitgf.dk. Ansvarsområderne er, at jeg har hjemtaget vores frontend kode fra eksterne leverandører og få bragt det ind i vores build setup i azure, fået opbygget webpack setup som optimere sitet og er nem at vedligeholde og kontinuerligt forbedre kodebase og performance. Vores frontend er opbygget omkring en sitecore løsning som vi i samarbejde med vores marketingsafdeling, medlemmerne og klubberne (afdelingerne) forbedre, tilpasser og nyudvikler funktioner til deres behov.
                            De store udfordringer er dog, at kodebasen (var) meget forældet og, at der har været mange hænder henover – hvor ændringer ikke har haft en decideret rød tråd eller på anden vis har været under opsyn.
                            Mit overordnet mål har været, at få vores website til at performe bedre end konkurrenternes og gøre vores kodebase så nem, at arbejde med, at mine kollegaer også kan foretage ændringer i det.
                            Derudover har jeg været med til, at omskrive visse dele af backenden som er skrevet i C# for, at få bedre data eller performance på frontenden.
                        </p>
                    </CardContent>
                </Card>

            </Paper>

    </Grid>
    <Grid item lg={6} xs={12} order={{xs:1, md:2}}>
        <Paper elevation={3} sx={{p: "1rem", backgroundColor: "var(--fourth-color)",boxSizing:"border-box", height:"100%"}}>
            <Card sx={{ maxWidth: "100%", height:"100%"}}>
                <CardMedia
                    component="img"
                    alt="Panda"
                    height="100%"
                    image="img.webp"
                />
            </Card>
        </Paper>

    </Grid>
</Grid>




            </Container>
        </Box>
    );
}

export default App;
